.pagination {
    justify-content: flex-end ;
}

ul#gender {
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
  ul#gender li {
    margin-top: 7px;
    display: inline;
    list-style: none;
    margin-right: 25px;
  }
  